import React, { useState } from 'react'
import SubHero from '../components/SubHero'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

const jobs = [
  {
    title: 'Senior React/React Native Developer',
    location: 'United States (Remote)',
    description: (
      <div>
        <br />
        <br />
        <h3>ROLES AND RESPONSIBILITIES:</h3>
        <ul>
          <li>
            3+ years of experience as a Front-End Developer, experience in Web
            and mobile application based projects (JavaScript, React,
            ReactNative)
          </li>
          <li>
            Design and implementation of web applications, specializing in the
            user interface of the product
          </li>
          <li>
            Perform hands-on software development tasks, such as prototyping,
            feature development, bug fixing, browser testing
          </li>
          <li>
            Liaise with the product architect and developers on software design,
            dependencies and code maintainability
          </li>
          <li>
            Liaise with the UI designers on design and implementation of
            interfaces
          </li>
          <li>
            Provide time and size estimates for project tasks, The ability to
            work independently to drive development
          </li>
          <li>
            Promote the use of React, JavaScript, HTML, CSS JavaScript best
            practices
          </li>
          <li>
            UI design chops - knowing visually what works and what doesn't,
            Passion for semantics, usability, efficiency and performance based
            code – craftsmanship
          </li>
        </ul>
        <h3>SKILLS:</h3>
        <ul>
          <li>
            Expert understanding of core front-end web technologies (JavaScript,
            HTML5, CSS3, Sass/LESS)
          </li>
          <li>React and React Native</li>
          <li>
            Experience using front-end frameworks such as Bootstrap, Ionic, &
            Material UI
          </li>
          <li>
            Good understanding of AJAX and JavaScript Dom manipulation
            Techniques
          </li>
          <li>
            Experience implementing complex interfaces across multiple platforms
            and browsers
          </li>
          <li>
            Passion for semantics, usability, efficiency and performance based
            code – craftsmanship
          </li>
          <li>
            Keen desire for innovation - to learn new languages and frameworks
          </li>
          <li>The ability to work independently to drive development</li>
          <li>
            Experience with RESTful services, Experience with NodeJS, Experience
            with GraphQL
          </li>
          <li>
            Familiar with testing frameworks (Ex. Karate, Jasmine, Wiremock,
            Jbehave, Lint)
          </li>
          <li>Agile and Scrum project experience, Usage of JIRA</li>
          <li>
            Ability to logon to Ubuntu & Unix boxes for debugging and deployment
          </li>
          <li>Nice to have AWS experience</li>
          <li>React with redux</li>
          <li>React with context</li>
          <li>React native</li>
          <li>Expert in css, javascript core</li>
          <li>Node js (added advantage)</li>
        </ul>
      </div>
    ),
  },
  {
    title: 'Junior React/React Native Developer',
    location: 'United States (Remote)',
    description: (
      <div>
        <br />
        <br />
        <h3>ROLES AND RESPONSIBILITIES:</h3>
        <ul>
          <li>
            1+ years of experience as a Front-End Developer, experience in Web
            and mobile application based projects (JavaScript, React,
            ReactNative)
          </li>
          <li>
            Design and implementation of web applications, specializing in the
            user interface of the product
          </li>
          <li>
            Perform hands-on software development tasks, such as prototyping,
            feature development, bug fixing, browser testing
          </li>
          <li>
            Liaise with the product architect and developers on software design,
            dependencies and code maintainability
          </li>
          <li>
            Liaise with the UI designers on design and implementation of
            interfaces
          </li>
          <li>
            Provide time and size estimates for project tasks, The ability to
            work independently to drive development
          </li>
          <li>
            Promote the use of React, JavaScript, HTML, CSS JavaScript best
            practices
          </li>
          <li>
            UI design chops - knowing visually what works and what doesn't,
            Passion for semantics, usability, efficiency and performance based
            code – craftsmanship
          </li>
        </ul>
        <h3>SKILLS:</h3>
        <ul>
          <li>
            Expert understanding of core front-end web technologies (JavaScript,
            HTML5, CSS3, Sass/LESS)
          </li>
          <li>React and React Native</li>
          <li>
            Experience using front-end frameworks such as Bootstrap, Ionic, &
            Material UI
          </li>
          <li>
            Good understanding of AJAX and JavaScript Dom manipulation
            Techniques
          </li>
          <li>
            Experience implementing complex interfaces across multiple platforms
            and browsers
          </li>
          <li>
            Passion for semantics, usability, efficiency and performance based
            code – craftsmanship
          </li>
          <li>
            Keen desire for innovation - to learn new languages and frameworks
          </li>
          <li>The ability to work independently to drive development</li>
          <li>
            Experience with RESTful services, Experience with NodeJS, Experience
            with GraphQL
          </li>
          <li>
            Familiar with testing frameworks (Ex. Karate, Jasmine, Wiremock,
            Jbehave, Lint)
          </li>
          <li>Agile and Scrum project experience, Usage of JIRA</li>
          <li>
            Ability to logon to Ubuntu & Unix boxes for debugging and deployment
          </li>
          <li>Nice to have AWS experience</li>
          <li>React with redux</li>
          <li>React with context</li>
          <li>React native</li>
          <li>Expert in css, javascript core</li>
          <li>Node js (added advantage)</li>
        </ul>
      </div>
    ),
  },
]

const Careers = () => {
  const [openJobs, setOpenJobs] = useState([])

  const toggleJob = id => {
    if (openJobs.indexOf(id) === -1) {
      setOpenJobs([...openJobs, id])
    } else {
      setOpenJobs([...openJobs.filter(item => item !== id)])
    }
  }

  return (
    <Layout>
      <Helmet
        title="Careers | NEXTGEN Code Company"
        meta={[
          {
            name: 'description',
            content:
              'Work at NEXTGEN as a full-stack, front-end or back-end developer doing mobile apps, websites, SEO, web applications, shopify and more.',
          },
        ]}
      />
      <SubHero title="Careers" subtitle="Work for Tango" />
      <section className="section careers-page">
        <div className="container">
          <div className="columns">
            <div className="column">
              <h1>Available Positions</h1>
              {jobs.map((job, index) => (
                <div
                  className="job-card"
                  key={index}
                  onClick={() => toggleJob(index)}
                >
                  <div className="job-card-header">
                    <div>
                      <h3>{job.title}</h3>
                      <p>{job.location}</p>
                    </div>
                    <div>
                      <svg
                        style={{
                          marginRight: '5px',
                          transform:
                            openJobs.indexOf(index) === -1
                              ? 'rotate(-90deg)'
                              : 'rotate(90deg)',
                        }}
                        width="20px"
                        height="20px"
                        fill="#121212"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m217.9 256 127.1-127c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0l-144 143.9c-9.1 9.1-9.3 23.7-.7 33.1l144.6 144.9c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9z" />
                      </svg>
                    </div>
                  </div>

                  {openJobs.indexOf(index) !== -1 && job.description}
                </div>
              ))}
              <h1>Apply</h1>

              <form
                className="project-discovery-form"
                name="job_application"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input
                  type="hidden"
                  name="form-name"
                  value="job_application"
                  required
                />

                <div className="field">
                  <label className="label">Name</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      name="name"
                      placeholder="Full name"
                      required
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Job Applying For</label>
                  <div className="control">
                    <select className="select" name="applying_for">
                      {jobs.map((item, index) => (
                        <option key={index}>{item.title}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Email</label>
                  <div className="control">
                    <input
                      className="input"
                      type="email"
                      name="email"
                      placeholder="Email"
                      required
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Phone number</label>
                  <div className="control">
                    <input
                      className="input"
                      type="phone"
                      name="phone_number"
                      placeholder="Phone #"
                      required
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">LinkedIn Link</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      name="LinkedIn"
                      placeholder="LinkedIn Link"
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">GitHub Link</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      name="GitHub"
                      placeholder="GitHub Link"
                    />
                  </div>
                </div>

                <div className="full-width">
                  <div className="field full-width">
                    <label className="label">
                      What kind of job do <b>YOU</b> want?
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        name="what_do_you_want_to_do"
                        placeholder="..."
                        required
                      />
                    </div>
                  </div>
                  <p>
                    The best innovators are passionate about their work. And
                    passionate people are typically doing what they want. So
                    let's do that.
                  </p>
                </div>
                <div className="button-container">
                  <button className="btn btn--dark" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Careers
